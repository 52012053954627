import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from "@hookform/error-message";
import styled from 'styled-components'
import { useLoginMutation } from '../../app/services/auth'
import type { LoginRequest } from '../../app/services/auth'
import { EdenLivingLogo, GlobalStyle, StyledErrorMessage, StyledInput } from '../../components/customComponents'
import { useAuth } from '../../hooks/useAuth';

const LoginForm = styled.form`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background-color: #214F50;
  overflow: hidden;
`
const SubmitButton = styled.input`
  background: #E26C56;
  width: 166px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  margin-top: 46px;
  border-color: transparent;
`

export const Login = () => {
  const navigate = useNavigate()
  const { saveCredentials } = useAuth()

  const [login, { isLoading }] = useLoginMutation()

  const { handleSubmit, formState: { errors }, control } = useForm<LoginRequest>();

  const onSubmit = async (data: LoginRequest) => {
    try {
      const response = await login(data).unwrap()
      if (!response.code) {
        saveCredentials(response.data);
        navigate('/private/referral-records')
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <LoginForm onSubmit={handleSubmit(onSubmit)}>
      <GlobalStyle />
      <div style={{ height: 100}} />
      <EdenLivingLogo alt='logo' />
      {/* register your input into the hook by invoking the "register" function */}
      <div style={{ height: 66}} />
      <Controller
        render={({ field }) => <StyledInput placeholder='email' {...field} /> }
        name="email"
        rules={{ required: "username is required" }}
        control={control}
      />
      <StyledErrorMessage style={{marginLeft: 24}} >
      <ErrorMessage
        errors={errors}
        name="email"
        render={({ message }) => <p>{message}</p>}
      />
      </StyledErrorMessage>
      <div style={{ height: 16}} />
      {/* include validation with required or other standard HTML validation rules */}
      <Controller
        render={({ field }) => <StyledInput placeholder='password' {...field} type="password" /> }
        name="password"
        rules={{ required: "password is required" }}
        control={control}
      />      {/* errors will return when field validation fails  */}
      <StyledErrorMessage style={{marginLeft: 24}}>
      <ErrorMessage
        errors={errors}
        name="password"
        render={({ message }) => <p>{message}</p>}
      />
      </StyledErrorMessage>
      <SubmitButton type="submit" value="Log In" />
      {isLoading && <div></div>}
  </LoginForm>
  )
}

export default Login
