import { api, BaseResponse } from './api'

export interface User {
  email: string;
  id: number;
  name: string;
  phone: string;
  role: "SALES" | 'MERCHANT';
}


export interface UserResponse {
  user: User
  token: string
}

export interface LoginRequest {
  email: string
  password: string
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<BaseResponse<UserResponse>, LoginRequest>({
      query: (credentials) => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
})

export const { useLoginMutation } = authApi
