import * as React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import { Merchant } from '../../app/services/merchant';
import styled from 'styled-components';
import {QRCodeCanvas} from 'qrcode.react';
import { CanvasToImg } from 'react-canvas-to-img';

export interface IMerchantQRCodeProps {
}

const StyledRoot = styled.div`
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    .qrcode-url {
        margin-top: 40px;
        font-size: 14px;
        line-height: 16px;
        text-decoration-line: underline;
        color: #214F50;
        text-align: center;
        margin-bottom: 56px;
    }
    .tips {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #000000;
      margin-top: 16px;
    }
`
const WEB_ROOT = `${window.location.protocol}//${window.location.host}`;

export default function MerchantQRCode (props: IMerchantQRCodeProps) {
    const location = useLocation();
    const merchant = location.state as Merchant;
    const qrcodeUrl = `${WEB_ROOT}/r01/${merchant.id}`
  return (
    <StyledRoot>
        <Header title={merchant.name} />
        <div className='content'>
        <a href={qrcodeUrl} className='qrcode-url'>{qrcodeUrl}</a>
        <CanvasToImg>
        <QRCodeCanvas
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            size={240}
            value={qrcodeUrl}
        />
        </CanvasToImg>
        <div className='tips'>Long press the QR code to save</div>
        {/* <SubmitButton onClick={onImageCownload} style={{width: 250}} type="button" value="Save the QR code to album" /> */}
        </div>
    </StyledRoot>
  );
}
