import styled, { createGlobalStyle } from 'styled-components'
import { Input } from 'antd'
const { TextArea } = Input

export const StyledInput = styled(Input)`
    border-radius: 8px;
    padding: 16px;
    width: 327px;
`

export const SubmitButton = styled.input`
  background: #E26C56;
  width: 166px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  margin-top: 46px;
  border-color: transparent;
`

export const EdenLivingLogo = styled.img`
    width: 94px;
    height: 48px;
    content: url("../logo.png")
`

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const StyledErrorMessage = styled.div`
    height: 24px;
    color: #E96123;
    align-self: flex-start;
`

export const StyledTextArea = styled(TextArea)`
    border-radius: 8px;
    padding: 16px;
    width: 327px;
`