import * as React from 'react';
import { useDrawer } from '../hooks/useDrawer';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { useAuth } from '../hooks/useAuth';
import { EdenLivingLogo } from './customComponents';
import { HeartFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


export interface IDrawerProps {
}

const DrawerRoot = styled(Drawer)`
.ant-drawer-body {
    padding: 0;
}
`
const DrawerContentContainer = styled.div`
display: flex;
flex-direction: column;
`

const DrawerHeader = styled.div`
    background: #214F50;
    width: "100%";
    height: 77px;
    display: flex;
    align-items: center;
    padding-left: 10px;
`

const StyledDrawerItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px 12px 18px;
    background-color: ${(props:any) => props.selected?'rgba(33, 79, 80, 0.08':'#fff'};
    .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        margin-left: 34px;
        color: ${(props:any) => props.selected?'background: #214F50':'rgba(0, 0, 0, 0.6);'};
    }
`

const DrawerItem = ({ title, onClick, selected}:{title: string; onClick: () => void; selected: boolean}) => {
    return <StyledDrawerItem style={{background: selected?'rgba(33, 79, 80, 0.08)':'#fff'}} onClick={onClick}>
            <HeartFilled style={{color: selected?'#214F50':'rgba(0, 0, 0, 0.6)'}} size={20} />
            <span className='title'>{title}</span>
        </StyledDrawerItem>
}

const COMMENT_ITEMS = [
    {key:0, title: "Referral records", route: '/private/referral-records'},
    {key:1, title: "Log out", route: 'logout'},
]

const SALES_ITEMS = [
    {key:2, title: "Merchant list", route: '/private/merchant-list'},
    {key:3, title: "Add a new merchant", route: '/private/merchant-create-or-update'},
]

export default function CustomDrawer (props: IDrawerProps) {
    const {drawerOpen, setDrawerOpenAction} = useDrawer();
    const [currentSelectedItemKey, setCurrentSelectedItemKey] = React.useState<number | undefined>(0);

    const [drawerItems, setDrawItems] = React.useState(COMMENT_ITEMS);

    const { userInfo, logout } = useAuth();
    const { user } = userInfo;

    const handleDrawerClose = () => {
        setDrawerOpenAction(false);
    }

    React.useEffect(() => {
        setDrawItems(COMMENT_ITEMS);
        if( user && user.role === 'SALES') {
            const copyDrawerItems = [...COMMENT_ITEMS];
            copyDrawerItems.splice(1, 0, SALES_ITEMS[0]);
            copyDrawerItems.splice(1, 0,SALES_ITEMS[1]);
            setDrawItems(copyDrawerItems);
        }
    }, [user])

    const navigate = useNavigate();
    
  return (
      <DrawerRoot
        closable={false}
        width="80vw"
        placement="left"
        onClose={handleDrawerClose}
        visible={drawerOpen}>
            <DrawerContentContainer>
                <DrawerHeader>
                    <EdenLivingLogo alt='logo' />
                </DrawerHeader>
                {drawerItems.map(item => 
                    (<DrawerItem key={item.key} selected={currentSelectedItemKey === item.key} title={item.title} onClick={() => {
                        setCurrentSelectedItemKey(item.key);
                        handleDrawerClose();
                        if (item.route === 'logout') {
                            handleDrawerClose();
                            logout();
                        } else {
                            navigate(item.route, { replace: true})
                        }
                    }}/>))}             
            </DrawerContentContainer>
    </ DrawerRoot>
  );
}
