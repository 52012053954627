import * as React from 'react';
import styled from 'styled-components';
import { MenuOutlined, LeftOutlined } from '@ant-design/icons';
import { useDrawer } from '../hooks/useDrawer';
import { useNavigate } from 'react-router-dom';


export interface IHeaderProps {
    hasDrawer?: boolean;
    title?: string;
}

const StyledLeftButton = styled.div`
width: 48px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
`

const HeaderRoot = styled.div`
    display: flex;
    flex-direction: row;
    height: 56px;
    align-items: center;
`
const StyledTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #000000;
`

export default function Header (props: IHeaderProps) {
    const { hasDrawer, title } = props;
    const navigate = useNavigate()

    const { drawerOpen, setDrawerOpenAction} = useDrawer();

    const handleMenuClick = () => {
        setDrawerOpenAction(!drawerOpen)
    }
    const handleBackClick = () => {
        navigate(-1);
    }
  return (
    <HeaderRoot>
        <StyledLeftButton>{hasDrawer ? <MenuOutlined onClick={handleMenuClick} />: <LeftOutlined onClick={handleBackClick} />}</StyledLeftButton>
        <StyledTitle>{title}</StyledTitle>
    </HeaderRoot>
  );
}
