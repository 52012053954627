import { useMemo } from "react"
import { useSelector } from 'react-redux'
import { selectCurrentComponents, setDrawerOpen } from '../features/components/componentsSlice'
import { useAppDispatch } from "./store";

export const useDrawer = () => {                
    const { drawerOpen } = useSelector(selectCurrentComponents);
    const dispatch = useAppDispatch();
    const setDrawerOpenAction = (open: boolean) => {
        dispatch(setDrawerOpen(open))
    }
    return {drawerOpen: useMemo(() => (drawerOpen), [drawerOpen]), setDrawerOpenAction}
}