import * as React from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {Checkbox} from 'antd';
import {PostParticipantType, useGetMerchantByIdQuery, usePostParticipantsMutation} from '../../app/services/merchant';
import {StyledInput, SubmitButton, StyledErrorMessage} from '../../components/customComponents';
import {getParams} from '../../utils/util';
import {ErrorMessage} from '@hookform/error-message';
import {Modal, Typography} from 'antd';
import {useEffect, useState} from "react";
import Lottie from 'lottie-react-web'
import success from '../../animation/success.json'

export interface IGetVoucherProps {

}

const StyledRoot = styled.div`
  background: #0A5A5B;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Bilo';
  position: relative;
  overflow-y: scroll;

  .header-img {
    width: 100%;
    height: 234px;
  }

  .login {
    position: absolute;
    top: 16px;
    right: 16px;
    color: white;
    font-size: 16px;
    text-decoration: underline;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #FEFEFE;
    margin-top: 32px;
  }

  .desc {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FEFEFE;
    margin: 16px 32px 0px 32px;
  }

  .title1 {
    margin-top: 40px;
    font-family: 'Bilo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #FEFEFE;
  }

  .ant-checkbox-wrapper {
    color: #FEFEFE;
  }

  .tips {
    font-size: 12px;
    line-height: 12px;
    color: #FEFEFE;
    margin-top: 16px;
  }

  .contact {
    width: 311px;
    height: auto;
    margin: 32px;
  }
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`

const FieldLabel = styled.div`
  color: #fff;
  align-self: flex-start;
  margin-bottom: 8px;
  ${(props: { required?: boolean }) => props.required ? `
     ::after {
        content: " *";
        color: #E96123;
     }
     ` : ""};
`

const options = [
  {label: '1 bed', value: '1'},
  {label: '2 beds', value: '2'},
  {label: '3 beds', value: '3'},
];

// const StyledErrorMessage = styled.div`
//     height: 24px;
//     color: #E96123;
//     align-self: flex-start;
// `
/* eslint-disable no-useless-escape */
export default function GetVoucher(props: IGetVoucherProps) {
  const location = useLocation()
  const userId = getParams(location.pathname);
  const {handleSubmit, formState: {errors}, control, register} = useForm<PostParticipantType>();
  const [postParticipants] = usePostParticipantsMutation();
  const {data: merchant} = useGetMerchantByIdQuery(userId);
  const navigate = useNavigate();

  const onSubmit = async (data: PostParticipantType) => {
    try {
      if (userId) {
        data.fromUserId = userId
      }
      const response = await postParticipants(data).unwrap();
      if (!response.code) {
        Modal.info({
          icon: null,
          content: (
            <div style={{
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Lottie
                style={{
                  width: 200,
                  height: 200
                }}
                options={{
                  animationData: success
                }}
              />
              <div style={{fontSize: 18, fontWeight: 600}}>Thank you!</div>
              <div>We'll be in touch shortly</div>
            </div>
          ),
          onOk: ()=>{
            window.open('https://www.edenliving.co.nz/properties/barrys-point', '_blank')
          },
        });
      } else {
        Modal.error({
          content: (
            <div>
              <p>{response.msg}</p>
            </div>
          ),
          onOk() {
          },
        });
      }
    } catch (error) {
      Modal.error({
        content: (
          <div>
            <p>An error occurred, please try again later. </p>
          </div>
        ),
        onOk() {
        },
      });
    }
  }

  if (!userId) {
    return <div>something went wrong</div>
  }

  return (
    <StyledRoot>
      <a style={{
        position: 'relative'
      }}>
        <img
          onClick={() => {
            window.location.href = 'https://www.edenliving.co.nz/properties/barrys-point'
          }}
          className='header-img'
          src='https://res.cloudinary.com/shenmanz/image/upload/v1664239529/shenma/edenliving/eden-living-top2.jpg'
          alt='header'/>
        <div
          onClick={() => {
            navigate('/login')
          }}
          className='login'>
          Login
        </div>
      </a>
      {/*{merchant && <>*/}
      {/*  <div className='title'>{merchant.data.voucherName}</div>*/}
      {/*  <Typography className='desc'>{merchant.data.desc}</Typography>*/}
      {/*</>}*/}
      <div className='title1'>Registration</div>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {/* <Space style={{alignSelf: 'center'}} direction="vertical"> */}
        <input {...register("fromUserId")} style={{display: 'none'}}/>
        <FieldLabel required>Name</FieldLabel>
        <Controller
          render={({field}) => (<StyledInput {...field} />)}
          name="name"
          rules={{required: 'Please enter your name'}}
          control={control}
        />
        <StyledErrorMessage>
          <ErrorMessage
            errors={errors}
            name="name"
            render={({message}) => <p>{message}</p>}
          />
        </StyledErrorMessage>
        <FieldLabel required>Email</FieldLabel>
        <Controller
          render={({field}) => (<StyledInput {...field} />)}
          name="email"
          rules={{
            required: "Email is required",
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Please enter a valid email'
            }
          }}
          control={control}
        />
        <StyledErrorMessage>
          <ErrorMessage
            errors={errors}
            name="email"
            render={({message}) => <p>{message}</p>}
          />
        </StyledErrorMessage>
        <FieldLabel required>Phone Number</FieldLabel>
        <Controller
          render={({field}) => (<StyledInput {...field} />)}
          name="phone"
          rules={{required: 'Please enter your phone'}}
          control={control}
        />
        <StyledErrorMessage>
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({message}) => <p>{message}</p>}
          />
        </StyledErrorMessage>
        <FieldLabel>Interest</FieldLabel>
        <Controller
          render={({field}) => (<Checkbox.Group style={{alignSelf: 'flex-start'}}  {...field} options={options}/>)}
          name="interest"
          control={control}
        />
        {/* </Space> */}
        <SubmitButton style={{background: "#FEC017", borderRadius: 4, width: 311}} type="submit" value="Submit"/>
      </StyledForm>
      <div className='tips'>we will contact you ASAP</div>
      <a href='https://www.edenliving.co.nz/properties/barrys-point'><img alt=''
                                                                          src="https://res.cloudinary.com/shenmanz/image/upload/v1657761088/shenma/edenliving/eden-living-bottom.png"
                                                                          className='contact'/></a>
    </StyledRoot>
  );
}
