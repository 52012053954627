import * as React from 'react';
import {
  Participant,
  useListMerchantsWithParticipantsQuery,
  useListParticipantsQuery
} from '../../app/services/merchant';
import Header from '../../components/Header';
import {List} from 'antd';
import VirtualList from 'rc-virtual-list';
import useListHeight from '../../hooks/useListHeight';
import styled from 'styled-components';
import {Dropdown, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import {useAuth} from '../../hooks/useAuth';
import {GlobalStyle} from '../../components/customComponents';
import dayjs from 'dayjs';


export interface IMerchantListProps {
}

const StyledRecordsCard = styled.div`
  min-height: 104px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1;

  .name {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
  }

  .voucher-name {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
  }

  .op-container {
    margin-top: 8px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

const ReferralRecordCard = (props: Participant) => {
  const {firstName, lastName, email, user, createdAt, meta, showInfo} = props;
  const {phone, interest} = meta || {};


  return <StyledRecordsCard>
    <div className='name'>{firstName}{' '}{lastName}</div>
    {showInfo && (
      <div>
        <div className='voucher-name'>{email}</div>
        {phone && <div className='voucher-name'>{phone}</div>}
      </div>
    )}
    {interest && <div className='voucher-name'>{interest.map((i: string) => i === "1" ? "1 Bed " : `${i} Beds `)}</div>}
    <div className='op-container'>
      <span>{dayjs(createdAt).format('DD/MM/YYYY HH:mm')}</span>
      <span style={{marginLeft: 16}}>{user.name}</span>
    </div>
  </StyledRecordsCard>
}

const DropDownContainer = styled.div`
  height: 48px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 16px 0px 16px;
  padding: 16px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }
`

const StyledRoot = styled.div`
  .result {
    line-height: 16px;
    margin: 16px 0px 0px 16px;
  }
`

const StyledFilter = styled.div`

`

const Filter = ({
                  selectedUserId,
                  onUserSelected
                }: { selectedUserId: string; onUserSelected: (useId: number) => void }) => {

  const {data: merchantsWithParticipants} = useListMerchantsWithParticipantsQuery();

  const handleUserSelected = ({key}: any) => {
    onUserSelected(key);
  }

  return <StyledFilter>
    {merchantsWithParticipants &&
      <DropDownContainer><Dropdown overlayStyle={{paddingLeft: 16, paddingRight: 16, width: '100%'}}
                                   overlay={() => (<Menu
                                     selectable
                                     onSelect={handleUserSelected}
                                     items={[{
                                       key: '',
                                       label: 'All'
                                     }, ...merchantsWithParticipants?.data.map((m) => ({
                                       label: m.name,
                                       key: m.id + ''
                                     }))]}
                                   />)}>
        <div className='content'>
          <div
            style={{flexGrow: 1}}>{selectedUserId ? merchantsWithParticipants.data.find(d => d.id === Number(selectedUserId))?.name : 'Filter by business name'}</div>
          <DownOutlined/>
        </div>
      </Dropdown>
      </DropDownContainer>}
  </StyledFilter>
}

export default function ReferralRecords(props: IMerchantListProps) {

  const {userInfo} = useAuth();
  const {user} = userInfo;
  const isSALES = React.useMemo(() => user.role === 'SALES', [user])
  const listHeight = useListHeight(isSALES);
  const [params, setParams] = React.useState<{ page: number, fromUserId?: string }>({
    page: 1,
    fromUserId: isSALES ? undefined : user.id
  });
  const {data: participants} = useListParticipantsQuery(params);

  const [data, setData] = React.useState<Participant[]>([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    if (participants) {
      setData(data => [...data, ...participants.data.data]);
      setTotalPage(participants.data.totalPages);
      setTotal(participants.data.total);
    }
  }, [participants]);

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === listHeight) {
      if (params.page < totalPage) {
        setParams(params => ({...params, page: params.page + 1}))
      }
    }
  }

  const handleUserSelected = React.useCallback((useId: number) => {
    setParams({page: 1, fromUserId: useId + ''});
    setData([]);
  }, [])

  return (
    <StyledRoot>
      <GlobalStyle/>
      <Header hasDrawer title='Referral Records'/>
      {isSALES && <>
        <Filter selectedUserId={params.fromUserId || ''} onUserSelected={handleUserSelected}/>
        {!!total && <div className='result'>{total} {total > 1 ? 'Results' : 'Result'}</div>}
      </>}
      <List style={{paddingLeft: 16, paddingRight: 16}}>
        <VirtualList
          data={data}
          height={listHeight}
          itemHeight={128}
          itemKey="id"
          onScroll={onScroll}
        >
          {(item: Participant) => (
            <List.Item key={item.id}>
              <ReferralRecordCard {...item} showInfo={isSALES}/>
            </List.Item>
          )}
        </VirtualList>
      </List>
    </StyledRoot>
  );
}
