import React from 'react';
import './App.less';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './features/auth/Login';
import { PrivateOutlet } from './utils/PrivateOutlet';
import ReferralRecords from './features/referralRecords/ReferralRecords';
import MerchantList from './features/merchant/MerchantList';
import CreateOrUpdateMerchant from './features/merchant/CreateOrUpdateMerchant';
import CustomDrawer from './components/CustomDrawer';
import MerchantQRCode from './features/merchant/MerchantQRCode';
import GetVoucher from './features/voucher/GetVoucher';

function App() {
  return (
    <>
       <Routes>
        <Route path="/" element={<Navigate to="/private/referral-records" replace />}/>        
        <Route path="/private" element={<PrivateOutlet />}>
          <Route path='referral-records' element={<ReferralRecords />} />
          <Route path='merchant-list' element={<MerchantList />} />
          <Route path='merchant-create-or-update' element={<CreateOrUpdateMerchant />} />
          <Route path='merchant-qrcode' element={<MerchantQRCode />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/r01/*" element={<GetVoucher />} />
      </Routes>
      <CustomDrawer />
    </>
  );
}

export default App;
