import { BaseResponse, enhancedApi } from './api'
import { User } from './auth';

export interface Merchant {
  id?: number;
  name: string;
  email: string;
  password: string;
  phone: string;
  voucherName: string;
  desc: string;
}

export type Participant = {
    id?: number;
    firstName: string;
    lastName: string;
    fromUserId: number;
    email: string;
    createdAt: string;
    user: User;
    meta: any;
    showInfo?: boolean
}

export type PostParticipantType = {
    name: string;
    email: string;
    phone: string;
    interest: number[];
    fromUserId: number;
}

interface ListResponse<T> {
    current: number
    pageSize: number
    total: number
    totalPages: number
    data: T[]
}
export interface MerchantRequest extends Merchant {

}

export const merchantApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    postMerchant: builder.mutation<BaseResponse<Merchant>, Partial<Merchant>>({
      query: (merchant) => ({
        url: 'user',
        method: 'POST',
        body: merchant,
      }),
      invalidatesTags: (result) => [
        { type: 'users', id: result?.data.id},
        { type: 'users', id: 'PARTIAL-LIST' },
      ],   
     }),
     getMerchantById: builder.query<BaseResponse<Merchant>, number | null>({
        query: (id) => `user?id=${id}`,
    }),
     patchMerchant: builder.mutation<BaseResponse<Merchant>, Partial<Merchant>>({
        query: (merchant) => ({
          url: 'user',
          method: 'PATCH',
          body: merchant,
        }),
        invalidatesTags: (result) => [
          { type: 'users', id: result?.data.id},
          { type: 'users', id: 'PARTIAL-LIST' },
        ],   
    }),
    listMerchants: builder.query<BaseResponse<ListResponse<Merchant>>, Partial<{page: number; pageSize: number}>>({
        query: (params) => `users?page=${params.page}&pageSize=${params.pageSize?params.pageSize:20}`,
        providesTags: (result, error, page) => result?[
                // Provides a tag for each post in the current page,
                // as well as the 'PARTIAL-LIST' tag.
                ...result.data.data.map(({ id }) => ({ type: 'users' as const, id })),
                { type: 'users', id: 'PARTIAL-LIST' },
        ]: [{ type: 'users', id: 'PARTIAL-LIST' }],
    }),
    postParticipants: builder.mutation<BaseResponse<PostParticipantType>, Partial<Participant>>({
        query: (participant) => ({
          url: 'participant',
          method: 'POST',
          body: participant,
        }),
    }),
    listParticipants: builder.query<BaseResponse<ListResponse<Participant>>, Partial<{page: number; fromUserId: string}>>({
        query: (params) => `participant?page=${params.page}&fromUserId=${params.fromUserId?params.fromUserId:''}`,
        keepUnusedDataFor: 5,
    }),
    listMerchantsWithParticipants: builder.query<BaseResponse<Partial<Merchant>[]>, void>({
        query: () => `users-with-participant`,
        keepUnusedDataFor: 5,
    }),
  }),
})

export const { usePostMerchantMutation, useListMerchantsQuery, usePatchMerchantMutation, usePostParticipantsMutation, useListParticipantsQuery, useListMerchantsWithParticipantsQuery, useGetMerchantByIdQuery } = merchantApi
