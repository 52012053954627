import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Header from '../../components/Header';
import { StyledInput, StyledTextArea } from '../../components/customComponents'
import { MerchantRequest, usePatchMerchantMutation, usePostMerchantMutation } from '../../app/services/merchant';
import { ErrorMessage } from '@hookform/error-message';
import { useLocation, useNavigate } from 'react-router-dom';


export interface ICreateOrUpdateMerchantProps {
}

const SubmitButton = styled.input`
  background: #E26C56;
  width: 166px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #FFFFFF;
  margin-top: 46px;
  border-color: transparent;
`

const StyledRoot = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledForm = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`

export default function CreateOrUpdateMerchant (props: ICreateOrUpdateMerchantProps) {
    const location = useLocation();
    const { handleSubmit, formState: { errors }, control, register, setValue } = useForm<MerchantRequest>();
    const [postMerchant] = usePostMerchantMutation();
    const [patchMerchant] = usePatchMerchantMutation();

    const navigate = useNavigate();

    const onSubmit = async (data: MerchantRequest) => {
        try {
            let response
            if(!data.id) {
                response = await postMerchant(data).unwrap();
            } else {
                response = await patchMerchant(data).unwrap();
            }
            if(response && !response.code) {
                navigate('/private/merchant-list')
            }
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect(() => {
        if(location.state) {
            const state = location.state as MerchantRequest;
            if(state.id) {
                setValue("id", state.id);
                setValue('email', state.email);
                setValue('desc', state.desc);
                setValue('name', state.name);
                setValue('phone', state.phone);
                setValue('voucherName', state.voucherName);
                setValue('password', "●●●●●●");
            }
        }
    }, [setValue, location.state])

  return (
    <StyledRoot>
        <Header hasDrawer={!!!location.state} title='Add a new merchant' />
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <input {...register('id')} style={{display: 'none'}} />
        <div style={{ height: 16}} />

        {/* <Space size="middle" direction="vertical"> */}
        <Controller
            render={({ field }) => <StyledInput placeholder='Business Name' {...field} /> }
            name='name'
            rules={{ required: "business Name is required" }}
            control={control}
        />
        <ErrorMessage
            errors={errors}
            name='name'
            render={({ message }) => <p>{message}</p>}
        />
        <div style={{ height: 16}} />
        <Controller
            render={({ field }) => <StyledInput placeholder='email' {...field} /> }
            name="email"
            // eslint-disable-next-line
            rules={{ required: "Email is required", pattern: { value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: 'Please enter a valid email'} }}
            control={control}
        />
        <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => <p>{message}</p>}
        />
        <div style={{ height: 16}} />
        <Controller
            render={({ field }) => <StyledInput placeholder='password' {...field} onFocus={() => {
                if(location.state) {
                    if(field.value === '●●●●●●') {
                        setValue('password', '')
                    }
                }
            }} /> }
            name="password"
            rules={{ required: location.state ? false: "password is required" }}
            control={control}
        />
        <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => <p>{message}</p>}
        />
         <div style={{ height: 16}} />
        <Controller
            render={({ field }) => <StyledInput placeholder='phone' {...field} /> }
            name="phone"
            rules={{ required: "phone is required" }}
            control={control}
        />
        <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => <p>{message}</p>}
        />
          <div style={{ height: 16}} />
        <Controller
            render={({ field }) => <StyledInput placeholder='voucher Name' {...field} /> }
            name="voucherName"
            control={control}
        />
           <div style={{ height: 16}} />
        <Controller
            render={({ field }) => <StyledTextArea
            autoSize={{ minRows: 5, maxRows: 8 }}
            placeholder='description' {...field} /> }
            name="desc"
            control={control}
        />
        <SubmitButton type="submit" value={location.state?'Save Changes':'Confirm'} />
        {/* </Space> */}
        </StyledForm>
    </StyledRoot>
  );
}
