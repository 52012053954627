import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { User } from '../app/services/auth';
import { selectCurrentUser, setCredentials } from '../features/auth/authSlice';
import { useLocalStorage } from '../hooks/useLocalStorage';


const EMPTY_CREDENTIAL =  { user: null, token: '' }

export const useAuth = () => {
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch();
  const [localCredentials, setCredentialsToLocal] = useLocalStorage<{user: User | null, token: string}>("CREDENTIALS", EMPTY_CREDENTIAL)

  const logout = () => {
    dispatch(setCredentials(EMPTY_CREDENTIAL))
    setCredentialsToLocal(EMPTY_CREDENTIAL)
  }

  const saveCredentials = (userInfo: {user: User | null, token: string}) => {
    dispatch(setCredentials(userInfo))
    setCredentialsToLocal(userInfo)
  }

  useEffect(() => {
    dispatch(setCredentials(localCredentials))
  }, [localCredentials, dispatch])

  return { userInfo: useMemo(() => ({ user }), [user]), logout, saveCredentials }
}