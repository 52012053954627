import * as React from 'react';
import { Merchant, useListMerchantsQuery } from '../../app/services/merchant';
import Header from '../../components/Header';
import { List } from 'antd';
import VirtualList from 'rc-virtual-list';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { GlobalStyle } from '../../components/customComponents';

export interface IMerchantListProps {
}

const StyledRoot = styled.div`
   
`

const StyledMerchantCard = styled.div`
    height: 104px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    flex: 1;
    .name {
        font-size: 14px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.85);
    }
    .voucher-name {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.85);
    }
    .op-container {
        margin-top: 8px;
        font-size: 14px;
        line-height: 16px;
        color: #BE1005;
        display: flex;
        flex-direction: row;
    }
`

const MerchantCard = (props: Merchant) => {
    const {name, voucherName} = props;
    const navigate = useNavigate();

    const handleEditClick = () => {
        navigate('/private/merchant-create-or-update', {state: props})
    }

    const handleQRCodeClick = () => {
        navigate('/private/merchant-qrcode', {state: props})
    }

    return <StyledMerchantCard>
        <div className='name'>{name}</div>
        <div className='voucher-name'>{voucherName}</div>
        <div className='op-container'>
            <span onClick={handleEditClick}>Edit</span>
            <span style={{marginLeft: 16}} onClick={handleQRCodeClick}>Download QR Code</span>
        </div>

    </StyledMerchantCard>
}

export default function MerchantList (props: IMerchantListProps) {

    const [page, setPage] = React.useState<number>(1);
    const { data: merchants } = useListMerchantsQuery({page});
    const [data, setData] = React.useState<Merchant[]>([]);
    const [totalPage, setTotalPage] = React.useState(1);

    const { height } = useWindowDimensions();

    React.useEffect(() => {
        if(merchants) {
            setData([...data, ...merchants.data.data]);
            setTotalPage(merchants.data.totalPages);
        }
        // eslint-disable-next-line
    }, [merchants]);

    const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    
        if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === height -56) {
            if(page < totalPage) {
                setPage(page + 1);
            }
        }
    };

  return (
    <StyledRoot>
        <GlobalStyle />
      <Header hasDrawer title='Merchant list' />
      <List style={{paddingLeft: 16, paddingRight: 16}}>
        <VirtualList
            data={data}
            height={height - 56}
            itemHeight={129}
            itemKey="id"
            onScroll={onScroll}
            >
            {(item: Merchant) => (
            <List.Item key={item.id}>
            <MerchantCard {...item} />
            </List.Item>
            )}
        </VirtualList>
        </List>  
    </StyledRoot>
  );
}
