import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

type ComponentsState = {
  drawerOpen: boolean;
}

const slice = createSlice({
  name: 'components',
  initialState: { drawerOpen: false } as ComponentsState,
  reducers: {
    setDrawerOpen: (
      state,
      payload: PayloadAction <boolean>
    ) => {
      state.drawerOpen = payload.payload
    },
  },
})

export const { setDrawerOpen } = slice.actions

export default slice.reducer

export const selectCurrentComponents = (state: RootState) => state.components
